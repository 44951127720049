import React from "react";
import { graphql, Link } from "gatsby";
import Img from 'gatsby-image';
import Layout from "../components/layout";
import SEO from "../components/seo";

export default ({ data, pageContext }) => {
    console.log(pageContext);
    const { next, prev } = pageContext;
    const post = data.markdownRemark
    let featuredImgFluid = null;
    if (post.frontmatter.featuredImage) {
        featuredImgFluid = post.frontmatter.featuredImage.childImageSharp.fluid;
    }
    return (
        < Layout >
            <SEO title={post.frontmatter.title} description={post.excerpt} />
            <div className="blog-main">
                {featuredImgFluid ? <Img fluid={featuredImgFluid} /> : ''}
                <div className="blog-inner">
                    <h1>{post.frontmatter.title}</h1>
                    <div dangerouslySetInnerHTML={{ __html: post.html }} />
                    {next && <p><Link to={next.fields.slug}>Föregående: ({next.frontmatter.title})</Link></p>}
                    {prev && <p><Link to={prev.fields.slug}>Nästa: ({prev.frontmatter.title})</Link></p>}
                </div>
            </div>
        </Layout>
    );
}

export const pageQuery = graphql`
    query($slug: String! ) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            frontmatter {
                title
                featuredImage {
                    childImageSharp {
                      fluid(maxWidth: 800, traceSVG: {alphaMax:1.5, color: "blue"}) {
                        ...GatsbyImageSharpFluid
                        tracedSVG
                      }
                      
                    }
                }
            }
            excerpt
        }
    }
`;